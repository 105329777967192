import { useFormik } from "formik";
import React, { useContext, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import useSignIn from "react-auth-kit/hooks/useSignIn";

// assets
import { AppContext } from "../../AppContext";
import { ReactComponent as STEAM } from "../../assets/images/Frame (22).svg";
import { ReactComponent as TWITCH } from "../../assets/images/Frame (23).svg";
import { ReactComponent as METAMASK } from "../../assets/images/Frame (24).svg";
import TringleButton from "../../assets/images/Vector.png";
import SocialMediaButton from "../Common/Buttons/SocialMediaButton/SocialMediaButton";
import ReCAPTCHA from "react-google-recaptcha";

const GoogleSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 50 50"
      className="w-5 h-5 fill-gray-400"
    >
      <path d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z"></path>
    </svg>
  );
};

const Login = (props) => {
  const signIn = useSignIn();
  const { updateLoggedIn } = useContext(AppContext);
  const [invalid, setInvalid] = useState(false);
  const [FaCodeInput, setFaCodeInput] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [faCode, setFaCode] = useState(""); // Add state for 2FA code

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleRecaptchaChange = async (value) => {
    setCaptchaVerified(true);
    console.log("Recaptcha", value);
    try {
      const res = await axios.post(
        `http://${process.env.REACT_APP_API_URL}:5005/api/captcha`,
        {
          response: value,
        }
      );
      if (res.status == 200) {
        setCaptchaVerified(res.data.success);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const reqData = {
          email: values.email,
          password: values.password,
        };
        if (faCode.length > 0) reqData["twoFactorCode"] = faCode;
        const response = await axios.post(
          `http://${process.env.REACT_APP_API_URL}:5005/api/login`,
          reqData
        );

        if (response.data.success) {
          console.log(response.data);
          signIn({
            auth: { token: response.data.token, type: "Bearer" },
            userState: {
              username: response.data.user.username,
              email: response.data.user.email,
              id: response.data.user.userId,
              method: "email",
            },
          });
          updateLoggedIn(true);
        } else {
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Login error:", error);
        if (error.response.data.message === "Invalid credentials") {
          setInvalid(true);
          return;
        }
      }
    },
  });

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group spacing-inputs">
            {invalid && <p className="text-red-500">Invalid credentials</p>}
            <label htmlFor="email" className="form-label">
              EMAIL <span className="required">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-input"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="youremail@domain.com"
            />
            {formik.errors.email && (
              <div className="error-message">{formik.errors.email}</div>
            )}
          </div>

          <div className="form-group spacing-inputs">
            <label htmlFor="password" className="form-label">
              PASSWORD <span className="required">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-input"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="********"
            />
            <span
              style={{
                color: "#B1B6C6",
                fontSize: "13px",
                cursor: "pointer",
                marginTop: "8px",
              }}
            >
              Forget Password?
            </span>
            {formik.errors.password && (
              <div className="error-message">{formik.errors.password}</div>
            )}
          </div>

          <div className="form-group">
            <div
              className="toggle-fa-code-input"
              onClick={() => setFaCodeInput(true)}
            >
              <span className="toggle-fa-code-text">2FA CODE</span>
              {!FaCodeInput && (
                <img
                  className="toggle-fa-code-image"
                  src={TringleButton}
                  alt="triangle-btn"
                />
              )}
            </div>
            {FaCodeInput ? (
              <input
                type="text"
                className="fa-code-input"
                placeholder="Code"
                value={faCode} // Bind the input value to faCode state
                onChange={(e) => setFaCode(e.target.value)} // Update faCode state on change
              />
            ) : null}

            {FaCodeInput ? (
              <span class="required-message">
                Required if you enabled two-factor authentication
              </span>
            ) : null}
          </div>

          {/* <div class="info-text-container">
            <p class="info-text">
              {FaCodeInput && (
                <>
                  <input
                    type="text"
                    className="fa-code-input"
                    placeholder="Code"
                  />
                  <span className="required-message">
                    Required if you enabled two-factor authentication
                  </span>
                </>
              )}
            </p>
          </div> */}

          <div className="info-text-container">
            <p className="info-text">
              This site is protected by reCAPTCHA and the Google Privacy Policy
              and Terms of Service apply.
            </p>
          </div>
          {/* <ReCAPTCHA
            style={{ borderRadius: "10px", marginTop: "2px" }}
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={handleRecaptchaChange}
          /> */}
          <button
            type="submit"
            className="register-button uppercase"
            // disabled={!captchaVerified} // Disable button if captcha is not verified
          >
            Login
          </button>
        </form>
      </div>

      <span
        style={{
          fontSize: "15px",
          justifyContent: "center",
          color: "#B1B6C6",
          width: "100%",
          display: "flex",
        }}
      >
        Or continue with
      </span>
      <div className="social-media-container">
        <SocialMediaButton socialIcon={GoogleSVG} socialName={"Google"} />
        {/* <SocialMediaButton socialIcon={TWITCH} socialName={"Twitch"} /> */}
        <SocialMediaButton
          socialIcon={METAMASK}
          socialName={"Metamask"}
          auth="login"
        />
      </div>
    </>
  );
};

export default Login;
