import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Providers = () => {
  const [providers, setProviders] = useState([]);
  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/gameProviders`
      );
      if (res.data) setProviders(res.data);
    };
    run();
  }, [setProviders]);
  return (
    <div>
      <h1 className="text-white text-2xl">PROVIDERS</h1>
      <div className="flex flex-col items-center justify-between gap-3">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full">
          {providers.map((provider, index) => (
            <Link
              key={index}
              to={`http://${process.env.REACT_APP_API_URL}:3000/provider/${provider._id}`}
            >
              <div
                className={`bg-[#20222f] w-52 h-40 rounded-xl mt-5 hover:mt-2 transition-all duration-300 flex flex-col gap-3 items-center justify-center`}
              >
                <h1 className="text-white text-xl">{provider._id}</h1>
                <h2 className="text-white/40">{provider.count}+ games</h2>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Providers;
