import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import Intercom from "@intercom/messenger-js-sdk";
import axios from "axios";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import ICON from "../../../assets/images/support.svg";

const SupportButton = () => {
  const { pathname } = useLocation();
  const { isChatBoxOpen, isChatBoxCollapsed } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const user = useAuthUser();

  const isOnSportsPage = pathname.includes("/sports");

  const handleOpenChatBox = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (user) {
      console.log(user);
      (async () => {
        const res = await axios.get(
          `http://${process.env.REACT_APP_API_URL}:5005/api/userHash/${user.id}`
        );
        console.log(res.data);
        Intercom({
          app_id: "u2voz6r1",
          alignment: "right",
          custom_launcher_selector: "#custom-launcher",
          hide_default_launcher: true,
          horizontal_padding: 20,
          vertical_padding: isOnSportsPage ? 60 : 20,
          name: user?.username,
          // email: user.emailAddresses[0].emailAddress,
          user_id: user?.id,
          action_color: "#008080",
          background_color: "#008080",
          // created_at: user?.createdAt,
          user_hash: res.data.hash,
        });
      })();
    }
  }, [isOnSportsPage, user]);

  return (
    <div id="custom-launcher" className="fixed bottom-10 right-10">
      <img src={ICON} alt="support" />
      {/* <StyledSupportButton
        style={{
          right: isOnSportsPage
            ? "348px"
            : isChatBoxOpen
            ? isChatBoxCollapsed
              ? "256px"
              : "348px"
            : "8px",
        }}
        onClick={handleOpenChatBox}
      >
        <SUPPORT />
      </StyledSupportButton>

      <Modal maxWidth={500} isOpen={isOpen} onClose={setIsOpen}>
        <CloseButtonModal onClick={() => setIsOpen(false)} />
        <h1>Text</h1>
      </Modal> */}
    </div>
  );
};

export default SupportButton;
