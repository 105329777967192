import React, { useContext, useEffect, useState } from "react";
import ImagesSection from "../../Common/ImagesSection/ImagesSection";
import NavigationHeader from "../../Common/NavigationHeader/NavigationHeader";
import LiveWinsSection from "../../Common/WinnerCard/LiveWinsSection";
import { StyledPageContainer } from "./styles";
import axios from "axios";
import Button from "../../Common/Buttons/Button";
import SearchAndFilters from "../../Common/SearchAndFilters/SearchAndFilters";
import { AppContext } from "../../../AppContext";

const Jackpot = () => {
  const category = "Casino";
  const subcategory = "LIVE_CASINO";

  const [games, setGames] = useState([]);
  const [pages, setPages] = useState(0);
  const [once, setOnce] = useState(false);
  const { searchState } = useContext(AppContext);
  const pageSize = 20;

  useEffect(() => {
    setOnce(false);
  }, [searchState]);

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        searchState.searchQuery.length > 0
          ? `http://${process.env.REACT_APP_API_URL}:5005/api/search?query=${searchState.searchQuery}`
          : `http://${process.env.REACT_APP_API_URL}:5005/api/filter?sort=plays&order=-1`
      );
      // console.log(res.data);
      if (res.data) {
        res.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) games.push(game);
        });
        setGames([]);
        setGames(res.data);
        console.log(games);
      }
    };
    if (!once) {
      if (searchState.searchQuery.length < 0) {
        run();
        setOnce(true);
      }
    }
  }, [once, searchState.searchQuery, games]);

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        `http://${
          process.env.REACT_APP_API_URL
        }:5005/getGamesList?type=jackpot&skip=${
          pages * pageSize
        }&limit=${pageSize}`
      );
      if (res.data) {
        res.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) games.push(game);
        });
        setGames(games);
        setPages(pages + 1);
        setOnce(true);
      }
    };
    if (!once) run();
  });

  const handleMore = async () => {
    const res = await axios.get(
      `http://${
        process.env.REACT_APP_API_URL
      }:5005/getGamesList?type=jackpot&skip=${
        pages * pageSize
      }&limit=${pageSize}`
    );
    if (res.data) {
      res.data.forEach((game) => {
        if (!game.id_hash.includes("mobile")) games.push(game);
      });
      setGames(games);
      setPages(pages + 1);
      setOnce(true);
    }
  };

  return (
    <StyledPageContainer>
      <LiveWinsSection title="Live wins" hasFilters={true} />

      <NavigationHeader isNftPage={false} />
      <SearchAndFilters hasSortByOptions={true} hasProviderOptions={true} />
      <div className="flex flex-col items-center justify-center gap-4">
        {/* <SearchAndFilters hasSortByOptions={true} hasProviderOptions={true} /> */}

        <ImagesSection
          category={category}
          subcategory={subcategory}
          images={games}
        />
        <Button className="button-load-more" onClick={handleMore}>
          Load More
        </Button>
      </div>
    </StyledPageContainer>
    // <div className="">
    //   <LiveWinsSection title="Live wins" hasFilters={true} />
    //   <NavigationHeader isNftPage={false} />
    //   {games.map((game, index) => (
    //     <HoverableImage
    //       src={game.image_portrait}
    //       alt={game.name}
    //       key={index}
    //       className="w-10 h-10"
    //     />
    //   ))}
    // </div>
  );
};

export default Jackpot;
