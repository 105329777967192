import React, { useContext, useState } from "react";
import axios from "axios";

import { AppContext } from "../../../../AppContext";
import { StyledBitcoinContent } from "../styles";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";

const SolanaContent = ({ handleBack }) => {
  const user = useAuthUser();
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const { isMobileScreen, balance } = useContext(AppContext);

  const handleSubmit = async () => {
    if (!address || !amount) {
      alert("Please fill in all fields");
      return;
    }
    console.log(balance, amount);
    if (parseFloat(balance) < parseFloat(amount)) {
      console.log("Insufficient balance");
      alert("Insufficient balance");
      return;
    }
    const res = await axios.post(
      `http://${process.env.REACT_APP_API_URL}:5005/api/withdraw`,
      {
        username: user.username,
        amount,
        address,
        currency: "60", // Changed to Solana currency code
      }
    );
    if (res.data) console.log(res.data);
    setSubmitted(true);
  };

  return (
    <StyledBitcoinContent>
      <div className="flex flex-col p-6 bg-[#1C1E2D] rounded-lg">
        <div className="flex max-sm:flex-col items-center justify-between mb-6">
          <div className="flex items-center">
            <div className="back-button mr-4" onClick={handleBack}>
              <svg viewBox="0 0 8 14" className="w-6 h-6 fill-white">
                <title>arrow</title>
                <path d="M8,12.534 C8.00312077,12.1491981 7.86017338,11.7775349 7.6,11.494 L3.348,7 L7.6,2.506 C8.14019021,1.91980519 8.14019021,1.01719481 7.6,0.431 C7.34808121,0.156127315 6.99235127,-0.000378973093 6.6195,-0.000378973093 C6.24664873,-0.000378973093 5.89091879,0.156127315 5.639,0.431 L0.407,5.963 C-0.135688789,6.54706274 -0.135688789,7.45093726 0.407,8.035 L5.634,13.569 C5.88591879,13.8438727 6.24164873,14.000379 6.6145,14.000379 C6.98735127,14.000379 7.34308121,13.8438727 7.595,13.569 C7.8548547,13.2872672 7.99785194,12.9172619 7.995,12.534" />
              </svg>
            </div>
            <img
              src="https://s2.coinmarketcap.com/static/img/coins/64x64/16116.png"
              className="coin-img mr-2 w-6 h-6"
              alt=""
            />
            <span className="text-2xl font-bold text-white">
              WITHDRAW SOLANA
            </span>
          </div>
          <span className="text-[#FFB018] cursor-pointer">
            View Transactions
          </span>
        </div>

        <p className="text-gray-400 mb-6">
          Please enter the Solana wallet address you wish to receive the funds
          on. Once confirmed, the withdrawal is usually processed within a few
          minutes.
        </p>

        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="address"
              className="text-gray-400 uppercase text-sm font-bold"
            >
              Receiving Solana Address <span className="text-red-500">*</span>
            </label>
            <input
              id="address"
              type="text"
              placeholder="Paste your Solana wallet address here"
              className="bg-[#2A2D3E] p-3 rounded-lg w-full text-white"
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-2">
            <label
              htmlFor="amount"
              className="text-gray-400 uppercase text-sm font-bold"
            >
              Withdrawal Amount <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center">
              <div className="flex-1 mr-2">
                <input
                  id="amount"
                  type="number"
                  step="0.000001"
                  placeholder="0.000000"
                  className="bg-[#2A2D3E] p-3 rounded-lg w-2/3 text-white"
                  onChange={(e) => setAmount(e.target.value)}
                />
              </div>
              <div className="bg-[#2A2D3E] p-3 rounded-lg text-white flex items-center">
                <img
                  src="https://s2.coinmarketcap.com/static/img/coins/64x64/16116.png"
                  className="mr-2 w-5 h-5"
                  alt=""
                />
                SOL
              </div>
            </div>
          </div>
          <p className="text-gray-400">Network Fee: $0.00001</p>
          <button
            className="bg-[#7CFC00] w-80 px-6 py-3 rounded-md text-black font-bold hover:bg-[#7CFC00]/80 transition-colors"
            onClick={handleSubmit}
          >
            REQUEST WITHDRAWAL
          </button>
        </div>
        <p className="text-gray-400 mt-4 text-sm">
          *You will receive the specified Solana amount to your withdrawal
          address
          <br />
          *The value subtracted from your balance may vary between now and the
          time we process your withdrawal
        </p>
      </div>
    </StyledBitcoinContent>
  );
};

export default SolanaContent;
