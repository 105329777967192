import React, { useState } from "react";
import { styled } from "styled-components";
import { ReactComponent as ArrowIcon } from "../../../../assets/images/arrow-left.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const StyledFiatContent = styled.div`
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .back-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 8px;
      margin-right: 16px;

      &:hover {
        opacity: 0.8;
      }
    }

    h2 {
      margin: 0;
      font-size: 22px;
      text-transform: uppercase;
    }
  }

  .content {
    background: rgba(203, 215, 255, 0.03);
    border-radius: 8px;
    padding: 24px;

    .amount-input {
      margin-bottom: 24px;

      label {
        display: block;
        color: #b1b6c6;
        margin-bottom: 8px;
      }

      input {
        width: 100%;
        background: rgba(15, 17, 26, 0.55);
        border: none;
        border-radius: 6px;
        padding: 12px;
        color: white;
        font-size: 16px;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba(255, 232, 26, 0.3);
        }
      }
    }

    .checkout-button {
      background: #ffe81a;
      border: none;
      border-radius: 8px;
      padding: 12px 24px;
      color: #141722;
      font-weight: 500;
      cursor: pointer;
      width: 100%;

      &:hover {
        background: #fff81a;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .error-message {
      color: #ff4949;
      margin-top: 8px;
      font-size: 14px;
    }

    .loading {
      opacity: 0.7;
    }
  }
`;

const FiatContent = ({ handleBack }) => {
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const numAmount = parseFloat(amount);
      if (isNaN(numAmount) || numAmount < 10) {
        throw new Error("Minimum deposit amount is $10");
      }

      const response = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/poof?amount=${numAmount}&currency=USD&payment=paypal`
      );

      if (response.status == 200) {
        console.log(response.data);
        window.location.href = response.data.payment_link;
      }
    } catch (err) {
      setError(err.message || "Failed to process deposit. Please try again.");
      setIsLoading(false);
    }
  };

  return (
    <StyledFiatContent>
      <div className="header">
        <button className="back-button" onClick={handleBack}>
          <ArrowIcon />
        </button>
        <h2>USD Deposit</h2>
      </div>

      <div className={`content ${isLoading ? "loading" : ""}`}>
        <form onSubmit={handleSubmit}>
          <div className="amount-input">
            <label>Enter Deposit Amount (USD)</label>
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              min="10"
              step="0.01"
              placeholder="0.00"
              required
              disabled={isLoading}
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button
            type="submit"
            className="checkout-button"
            disabled={isLoading || !amount}
          >
            {isLoading ? "Processing..." : "Continue to Checkout"}
          </button>
        </form>
      </div>
    </StyledFiatContent>
  );
};

export default FiatContent;
