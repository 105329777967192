import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import ImagesSection from "../../Common/ImagesSection/ImagesSection";
import NavigationHeader from "../../Common/NavigationHeader/NavigationHeader";
import LiveWinsSection from "../../Common/WinnerCard/LiveWinsSection";
import { StyledPageContainer } from "./styles";
import axios from "axios";
import Button from "../../Common/Buttons/Button";
import SectionHeader from "../../Common/SectionHeader/SectionHeader";
import { ReactComponent as HOT } from "../../../assets/images/hot.svg";
import { ReactComponent as MEGAWAYS } from "../../../assets/images/megaways.svg";
import { ReactComponent as JACKPOT } from "../../../assets/images/jackpot.svg";
import { ReactComponent as TOP } from "../../../assets/images/top.svg";
import { ReactComponent as CASINO } from "../../../assets/images/Frame (9).svg";
import { ReactComponent as SEARCH_ICON } from "../../../assets/images/navbar-search-icon.svg";
import useSlider from "../../../hooks/useSlider";
import Slider from "../../Common/Slider/Slider";
import SearchModal from "../../Modals/SearchModal/SearchModal";
import SearchAndFilters from "../../Common/SearchAndFilters/SearchAndFilters";
import ProvidersSection from "../../Common/ProvidersSection/ProvidersSection";
import { Link } from "react-router-dom";

const Casino = () => {
  const category = "Casino";
  const subcategory = "AK_ORIGINALS";

  const [games, setGames] = useState([]);
  const [megaways, setMegaways] = useState([]);
  const [jackpot, setJackpot] = useState([]);
  const [slots, setSlots] = useState([]);
  const [liveCasino, setLiveCasino] = useState([]);
  const [searchGames, setSearchGames] = useState([]);
  const [pages, setPages] = useState(0);
  const [once, setOnce] = useState(false);
  const { containerRef, scrollLeft, scrollRight } = useSlider();
  const pageSize = 20;

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/getGamesList?type=fast-games&skip=0&limit=${pageSize}`
      );
      const res2 = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/search?query=megaways`
      );
      const res3 = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/getGamesList?type=jackpot&skip=0&limit=${pageSize}`
      );
      const res4 = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/getGamesList?type=video-slots&skip=0&limit=${pageSize}`
      );
      const res5 = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/getGamesList?type=livecasino&skip=0&limit=${pageSize}`
      );
      if (res.data) {
        res.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) games.push(game);
        });
        setGames(games);
      }
      if (res2.data) {
        res2.data.forEach((game) => {
          if (!megaways.id_hash?.includes("mobile")) megaways.push(game);
        });
        setMegaways(megaways);
      }
      if (res3.data) {
        res3.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) jackpot.push(game);
        });
        setJackpot(jackpot);
      }
      if (res4.data) {
        res4.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) slots.push(game);
        });
        setSlots(slots);
      }
      if (res5.data) {
        res5.data.forEach((game) => {
          if (!game.id_hash.includes("mobile")) liveCasino.push(game);
        });
        setLiveCasino(liveCasino);
      }
      setOnce(true);
    };
    if (!once) run();
  });

  const handleMore = async () => {
    const res = await axios.get(
      `http://${
        process.env.REACT_APP_API_URL
      }:5005/getGamesList?type=fast-games&skip=${
        pages * pageSize
      }&limit=${pageSize}`
    );
    // console.log(res.data);
    if (res.data) {
      res.data.forEach((game) => {
        if (!game.id_hash.includes("mobile")) games.push(game);
      });
      setGames(games);
      setPages(pages + 1);
      // console.log(games);
      setOnce(true);
    }
  };

  const debounce = useRef(
    _.debounce(async (e) => {
      // console.log("calling...");
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/search?query=${e}`
      );
      if (res.data) {
        setSearchGames(res.data);
      }
    }, 300)
  ).current;

  const handleChange = (e) => debounce(e.target.value);

  const {
    scrollLeft: scrollLeftHot,
    scrollRight: scrollRightHot,
    containerRef: containerRefHot,
  } = useSlider();
  const {
    scrollLeft: scrollLeftNew,
    scrollRight: scrollRightNew,
    containerRef: containerRefNew,
  } = useSlider();
  const {
    scrollLeft: scrollLeftTop,
    scrollRight: scrollRightTop,
    containerRef: containerRefTop,
  } = useSlider();
  const {
    scrollLeft: scrollLeftJackpot,
    scrollRight: scrollRightJackpot,
    containerRef: containerRefJackpot,
  } = useSlider();
  const {
    scrollLeft: scrollLeftMegaways,
    scrollRight: scrollRightMegaways,
    containerRef: containerRefMegaways,
  } = useSlider();

  return (
    <StyledPageContainer>
      <LiveWinsSection title="Live wins" hasFilters={true} />

      <NavigationHeader isNftPage={false} />
      {/* <SearchAndFilters /> */}
      <div className="flex flex-col gap-4 items-start justify-start">
        <div className="flex flex-col gap-2 w-full">
          <SectionHeader
            iconHeader={HOT}
            casinoText="HOT GAMES"
            sideButton="View All"
            sideButtonLink="/casino"
            hasRecommended={true}
            scrollLeft={scrollLeftHot}
            scrollRight={scrollRightHot}
          />
          <Slider
            CardsComponent={games.map((e, index) => (
              <img
                src={e.image_portrait}
                alt=""
                id={e.id_hash}
                className="w-44 h-60 rounded-lg mt-5 hover:mt-2 transition-all duration-300"
                onClick={() =>
                  (window.location.href = `/casino/games/${e.id_hash}`)
                }
                onError={() => document.getElementById(e.id_hash).remove()}
              />
            ))}
            containerRef={containerRefHot}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <SectionHeader
            iconHeader={CASINO}
            casinoText="NEW GAMES"
            sideButton="View All"
            sideButtonLink="/casino"
            hasRecommended={true}
            scrollLeft={scrollLeftNew}
            scrollRight={scrollRightNew}
          />
          <Slider
            CardsComponent={slots.map((e, index) => (
              <img
                src={e.image_portrait}
                alt=""
                id={e.id_hash}
                className="w-44 h-60 rounded-lg mt-5 hover:mt-2 transition-all duration-300"
                onClick={() =>
                  (window.location.href = `/casino/games/${e.id_hash}`)
                }
                onError={() => document.getElementById(e.id_hash).remove()}
              />
            ))}
            containerRef={containerRefNew}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <SectionHeader
            iconHeader={TOP}
            casinoText="TOP GAMES"
            sideButton="View All"
            sideButtonLink="/casino"
            hasRecommended={true}
            scrollLeft={scrollLeftTop}
            scrollRight={scrollRightTop}
          />
          <Slider
            CardsComponent={liveCasino.map((e, index) => (
              <img
                src={e.image_portrait}
                alt=""
                id={e.id_hash}
                className="w-44 h-60 rounded-lg mt-5 hover:mt-2 transition-all duration-300"
                onClick={() =>
                  (window.location.href = `/casino/games/${e.id_hash}`)
                }
                onError={() => document.getElementById(e.id_hash).remove()}
              />
            ))}
            containerRef={containerRefTop}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <SectionHeader
            iconHeader={JACKPOT}
            casinoText="JACKPOT"
            sideButton="View All"
            sideButtonLink="/casino"
            hasRecommended={true}
            scrollLeft={scrollLeftJackpot}
            scrollRight={scrollRightJackpot}
          />
          <Slider
            CardsComponent={jackpot.map((e, index) => (
              <img
                src={e.image_portrait}
                alt=""
                id={e.id_hash}
                className="w-44 h-60 rounded-lg mt-5 hover:mt-2 transition-all duration-300"
                onClick={() =>
                  (window.location.href = `/casino/games/${e.id_hash}`)
                }
                onError={() => document.getElementById(e.id_hash).remove()}
              />
            ))}
            containerRef={containerRefJackpot}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <SectionHeader
            iconHeader={MEGAWAYS}
            casinoText="MEGAWAYS & MULTIWAYS"
            // sideButton="View All"
            sideButtonLink="/casino"
            hasRecommended={true}
            scrollLeft={scrollLeftMegaways}
            scrollRight={scrollRightMegaways}
          />
          <Slider
            CardsComponent={megaways.map((e, index) => (
              <img
                src={e.image_portrait}
                alt=""
                id={e.id_hash}
                className="w-44 h-60 rounded-lg mt-5 hover:mt-2 transition-all duration-300"
                onClick={() =>
                  (window.location.href = `/casino/games/${e.id_hash}`)
                }
                onError={() => document.getElementById(e.id_hash).remove()}
              />
            ))}
            containerRef={containerRefMegaways}
          />
        </div>

        <div className="flex flex-col gap-2 w-full">
          <ProvidersSection />
        </div>
      </div>
    </StyledPageContainer>
  );
};

export default Casino;
