import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

const Provider = () => {
  const providerId = useParams();
  const [games, setGames] = useState([]);

  useEffect(() => {
    const run = async () => {
      console.log(providerId);
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/gamesByProvider?provider=${providerId.providerid}`
      );
      if (res.status == 200) {
        const gamess = [];
        res.data.forEach((e) => {
          if (!e.id_hash.includes("mobile")) gamess.push(e);
        });
        setGames(gamess);
        console.log(res.data);
      }
    };
    run();
  }, [providerId]);

  return (
    <div className="flex flex-col items-start justify-start">
      <h1 className="text-2xl text-white">
        PROVIDER - {providerId.providerId}
      </h1>
      <div className="grid grid-cols-6">
        {games.map((game, index) => (
          <Link
            to={`http://${process.env.REACT_APP_API_URL}:3000/casino/games/${game.id_hash}`}
          >
            <img
              key={index}
              src={game.image_portrait}
              width={0}
              height={0}
              className="w-40 h-52 bg-contain rounded-xl hover:-mt-3 p-3 transition-all duration-300"
              alt=""
            />
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Provider;
