import React, { useEffect, useState } from "react";
// import { StyledSportsSection } from "./StyledSportsSection";
import { ReactComponent as CASINO } from "../../../assets/images/Frame (9).svg";
import SectionHeader from "../SectionHeader/SectionHeader";
import useSlider from "../../../hooks/useSlider";
import Slider from "../Slider/Slider";
import { Link } from "react-router-dom";
import axios from "axios";

const ProvidersSection = () => {
  const { containerRef, scrollLeft, scrollRight } = useSlider();
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/gameProviders`
      );
      if (res.data) setProviders(res.data);
    };
    run();
  }, [setProviders]);

  const CardsComponent = providers.map((provider, index) => {
    return (
      <Link key={index} to={`provider/${provider._id}`}>
        <div
          className={`bg-[#20222f] w-52 h-40 rounded-xl mt-5 hover:mt-2 transition-all duration-300 flex flex-col gap-3 items-center justify-center`}
        >
          <h1 className="text-white text-xl">{provider._id}</h1>
          <h2 className="text-white/40">{provider.count}+ games</h2>
        </div>
      </Link>
    );
  });

  return (
    <div>
      <SectionHeader
        iconHeader={CASINO}
        casinoText="PROVIDERS"
        sideButton="View All"
        sideButtonLink="/providers"
        hasRecommended={true}
        scrollLeft={scrollLeft}
        scrollRight={scrollRight}
      />
      <Slider CardsComponent={CardsComponent} containerRef={containerRef} />
    </div>
  );
};

export default ProvidersSection;
