import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import {
  COLLECTION_OPTIONS,
  PROVIDER_OPTIONS,
  SORT_BY_OPTIONS,
  TRAITS_OPTIONS,
} from "../../../assets/MockData/dropdownsData";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import Dropdown from "../Dropdowns/Dropdown";
import SwitchToggle from "../SwitchToggle/SwitchToggle";
import { SearchInput, StyledSearchFilters } from "./styles";
import ImagesSection from "../ImagesSection/ImagesSection";

const SearchAndFilters = ({
  hasSwitchToggle = false,
  labelSwitchToggle,
  hasProviderOptions = false,
  hasSortByOptions = false,
  hasCollectionOptions = false,
  hasTraitsOptions = false,
  providerOptions = PROVIDER_OPTIONS,
  sortByOptions = SORT_BY_OPTIONS,
  collectionOptions = COLLECTION_OPTIONS,
  traitsOptions = TRAITS_OPTIONS,
}) => {
  const {
    selectedOption,
    searchState,
    setSearchState,
    updateProvider,
    updateSort,
    updateCollection,
    updateTrait,
    setFilteredItems,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const location = useLocation();

  const [isToggle, setIsToggle] = useState(false);
  const [games, setGames] = useState([]);
  const [providers, setProviders] = useState([]);
  // const [providerGames, setProviderGames] = useState([]);
  // const previousSearchStateRef = useRef(searchState);

  useEffect(() => {
    const run = async () => {
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/gameProviders`
      );
      const arr = [];
      if (res.data) res.data.forEach((e) => providers.push({ label: e._id }));
      setProviders(providers);
    };
    run();
  }, [setProviders, providers]);

  useEffect(() => {
    const fetchGames = async () => {
      const res = await axios.get(
        `http://${process.env.REACT_APP_API_URL}:5005/api/gamesByProvider?provider=${searchState.selectedProvider}`
      );
      if (res.data && res.data.length > 0) setGames(res.data);
    };
    if (searchState.selectedProvider != "All") fetchGames();
  }, [searchState.selectedProvider]);

  useEffect(() => {
    const fetchGames = async () => {
      const query = new URLSearchParams();
      console.log(searchState.selectedSort);
      switch (searchState.selectedSort) {
        case "Popular":
          query.append("sort", "plays");
          query.append("order", "-1");
          break;
        case "Alphabetical":
          query.append("sort", "name");
          query.append("order", "1");
          break;
        case "Alphabetical Z-A":
          query.append("sort", "name");
          query.append("order", "-1");
          break;
        case "Prize Pool":
          query.append("sort", "rtp");
          query.append("order", "-1");
          break;
        case "None":
          break;
        default:
          query.append("sort", "plays");
          query.append("order", "-1");
      }
      console.log(query.toString());
      console.log(searchState.selectedSort);
      if (searchState.selectedSort == "None") {
        setGames([]);
        return;
      }
      const res = await axios.get(
        `http://${
          process.env.REACT_APP_API_URL
        }:5005/filter?${query.toString()}`
      );
      if (res.data) {
        setGames(res.data);
      }
    };
    fetchGames();
  }, [searchState.selectedSort]);

  useEffect(() => {
    updateProvider(providerOptions?.[0].label);
    updateSort(sortByOptions?.[0].label);
    updateCollection(collectionOptions?.[0].label);
    updateTrait(traitsOptions?.[0].label);

    return () => {
      setSearchState({ type: "UPDATE_SEARCH", payload: "" });
      updateProvider(providerOptions?.[0].label);
      updateSort(sortByOptions?.[0].label);
      updateCollection(collectionOptions?.[0].label);
      updateTrait(traitsOptions?.[0].label);
    };
  }, []);

  return (
    <StyledSearchFilters>
      <SearchInput
        type="text"
        placeholder={`Search ${
          selectedOption?.replace(/[^a-zA-Z]/g, " ")?.split("-") || " option"
        }`}
        value={searchState.searchQuery}
        onChange={(e) =>
          setSearchState({ type: "UPDATE_SEARCH", payload: e.target.value })
        }
      />
      {hasSwitchToggle && (
        <SwitchToggle
          checked={isToggle}
          toggle={setIsToggle}
          label={labelSwitchToggle}
        />
      )}
      {hasProviderOptions && (
        <Dropdown
          options={providers}
          label="Provider"
          selectedOption={searchState.selectedProvider}
          onSelectOption={updateProvider}
          hasSearchInput={true}
        />
      )}
      {hasSortByOptions && (
        <Dropdown
          options={sortByOptions}
          label="Sort by"
          selectedOption={searchState.selectedSort}
          onSelectOption={updateSort}
        />
      )}
      {hasCollectionOptions && (
        <Dropdown
          options={collectionOptions}
          label="Collection"
          selectedOption={searchState.selectedCollection}
          onSelectOption={updateCollection}
        />
      )}
      {hasTraitsOptions && (
        <Dropdown
          options={traitsOptions}
          label="Traits"
          selectedOption={searchState.selectedTrait}
          onSelectOption={updateTrait}
        />
      )}
      <ImagesSection images={games} />
    </StyledSearchFilters>
  );
};

export default SearchAndFilters;
