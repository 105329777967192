import { useFormik } from "formik";
import React, { useContext, useState, useRef, useEffect } from "react";
import * as Yup from "yup";
import axios from "axios";
// import { SignUp, useUser } from "@clerk/clerk-react";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import TelegramLogin from "react-telegram-auth";

//assets
import { AppContext } from "../../AppContext";
import { ReactComponent as STEAM } from "../../assets/images/Frame (22).svg";
import { ReactComponent as TWITCH } from "../../assets/images/Frame (23).svg";
import { ReactComponent as METAMASK } from "../../assets/images/Frame (24).svg";
import SocialMediaButton from "../Common/Buttons/SocialMediaButton/SocialMediaButton";
import { useParams } from "react-router-dom";

const GoogleSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100"
      height="100"
      viewBox="0 0 50 50"
      className="w-5 h-5 fill-gray-400"
    >
      <path d="M 26 2 C 13.308594 2 3 12.308594 3 25 C 3 37.691406 13.308594 48 26 48 C 35.917969 48 41.972656 43.4375 45.125 37.78125 C 48.277344 32.125 48.675781 25.480469 47.71875 20.9375 L 47.53125 20.15625 L 46.75 20.15625 L 26 20.125 L 25 20.125 L 25 30.53125 L 36.4375 30.53125 C 34.710938 34.53125 31.195313 37.28125 26 37.28125 C 19.210938 37.28125 13.71875 31.789063 13.71875 25 C 13.71875 18.210938 19.210938 12.71875 26 12.71875 C 29.050781 12.71875 31.820313 13.847656 33.96875 15.6875 L 34.6875 16.28125 L 41.53125 9.4375 L 42.25 8.6875 L 41.5 8 C 37.414063 4.277344 31.960938 2 26 2 Z M 26 4 C 31.074219 4 35.652344 5.855469 39.28125 8.84375 L 34.46875 13.65625 C 32.089844 11.878906 29.199219 10.71875 26 10.71875 C 18.128906 10.71875 11.71875 17.128906 11.71875 25 C 11.71875 32.871094 18.128906 39.28125 26 39.28125 C 32.550781 39.28125 37.261719 35.265625 38.9375 29.8125 L 39.34375 28.53125 L 27 28.53125 L 27 22.125 L 45.84375 22.15625 C 46.507813 26.191406 46.066406 31.984375 43.375 36.8125 C 40.515625 41.9375 35.320313 46 26 46 C 14.386719 46 5 36.609375 5 25 C 5 13.390625 14.386719 4 26 4 Z"></path>
    </svg>
  );
};

const Register = (props) => {
  // const telegramRef = useRef(null);
  // useEffect(() => {
  //   const scriptElement = document.createElement("script");
  //   scriptElement.src = "https://telegram.org/js/telegram-widget.js?22";
  //   scriptElement.setAttribute("data-telegram-login", "LotteryPlusBot");
  //   scriptElement.setAttribute("data-size", "small");
  //   scriptElement.setAttribute("data-auth-url", "https://134.209.147.156:3000");
  //   scriptElement.async = true;

  //   telegramRef.current.appendChild(scriptElement);
  // }, []);
  // const { isSignedIn, user } = useUser();
  // const { updateLoggedIn } = useContext(AppContext);
  const signIn = useSignIn();
  const auth = useAuthUser();
  const { updateLoggedIn } = useContext(AppContext);
  const [usernameExists, setUsernameExists] = useState(false);
  const { id } = useParams();
  console.log(id);

  if (auth) {
    updateLoggedIn(true);
    console.log(auth);
  }

  const handleTelegramResponse = (user) => {
    console.log(user);
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%?#])[A-Za-z\d@$!%?#]{8,}$/,
        "Password must contain at least 8 characters, one lowercase letter, one uppercase letter, one number, and one special symbol"
      ),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password: "",
      provider: "email",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        console.log("registering...");
        console.log(values);
        const response = await axios.post(
          `http://${process.env.REACT_APP_API_URL}:5005/api/register`,
          values
        );
        if (response.status == 200) {
          console.log(response.data);
          signIn({
            auth: { token: response.data.token, type: "Bearer" },
            userState: {
              username: response.data.user.username,
              email: response.data.user.email,
              id: response.data.user.userId,
              method: "email",
            },
          });
          updateLoggedIn(true);
        } else {
          console.error("Login failed");
        }
      } catch (error) {
        console.error("Failed to submit form", error);
        console.error("Error details:", error.response);
        if (error.response.data.message === "User already exists") {
          setUsernameExists(true);
          return;
        }
        // Display error message to user
        // You might want to set an error state here and display it in your UI
      }
    },
  });

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group spacing-inputs">
            {usernameExists && (
              <p className="text-red-500">User already exists</p>
            )}
            <label htmlFor="username" className="form-label">
              USERNAME <span className="required">*</span>
            </label>
            <input
              type="text"
              id="username"
              name="username"
              className="form-input"
              value={formik.values.username}
              onChange={formik.handleChange}
              placeholder="Username"
            />
            {formik.errors.username && (
              <div className="error-message">{formik.errors.username}</div>
            )}
          </div>

          <div className="form-group spacing-inputs">
            <label htmlFor="email" className="form-label">
              EMAIL <span className="required">*</span>
            </label>
            <input
              type="text"
              id="email"
              name="email"
              className="form-input"
              value={formik.values.email}
              onChange={formik.handleChange}
              placeholder="youremail@domain.com"
            />
            {formik.errors.email && (
              <div className="error-message">{formik.errors.email}</div>
            )}
          </div>

          <div className="form-group spacing-inputs">
            <label htmlFor="password" className="form-label">
              PASSWORD <span className="required">*</span>
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-input"
              value={formik.values.password}
              onChange={formik.handleChange}
              placeholder="********"
            />

            {/* <span
              style={{
                color: "#B1B6C6",
                fontSize: "13px",
                cursor: "pointer",
                marginTop: "8px",
              }}
            >
              Forget Password?
            </span>
            {formik.errors.password && (
              <div className="error-message">{formik.errors.password}</div>
            )} */}
          </div>

          <div style={{ color: "#fff", fontSize: "15px", marginTop: "20px" }}>
            <input
              type="checkbox"
              name="terms"
              style={{ marginRight: "10px" }}
            />
            I have read and agree to the{" "}
            <a
              href="/"
              style={{
                color: "#4DA8DA",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Terms and Conditions
            </a>
          </div>
          <div class="info-text-container">
            <p class="info-text">
              This site is protected by reCAPTCHA and the Google Privacy Policy
              and Terms of Service apply.
            </p>
          </div>

          <button type="submit" className="register-button uppercase">
            Register
          </button>
        </form>
      </div>

      <span
        style={{
          fontSize: "15px",
          justifyContent: "center",
          color: "#B1B6C6",
          width: "100%",
          display: "flex",
        }}
      >
        Or continue with
      </span>
      <div className="social-media-container">
        <SocialMediaButton socialIcon={GoogleSVG} socialName={"Google"} />
        {/* <SocialMediaButton socialIcon={TWITCH} socialName={"Twitch"} /> */}
        {/* <div ref={telegramRef} /> */}
        <SocialMediaButton socialIcon={METAMASK} socialName={"Metamask"} />
      </div>
    </>
  );
};

const checkUsernameExists = async (username) => {
  try {
    const response = await axios.post(
      `http://${process.env.REACT_APP_API_URL}:5005/api/api/check-username`,
      { username }
    );
    return response.data;
  } catch (error) {
    console.error("Error checking username:", error);
    return { success: false, error: error.message };
  }
};

export default Register;
